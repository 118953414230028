import {AxiosRequestConfig} from 'axios';
import {IdentityProviders} from '../../lib/identity-providers/identity-provider';
import {
    ApiResource,
    ApiReturn,
    axiosInstanceV2,
    CreateResource,
} from '../../lib/resources/resource';
import {FonomaError} from '../util/fonoma-error';
import {BasePerson} from '../common/models';

export interface OauthSessionCreate {
    autoLogin: boolean;
    lang: string;
    currency: string;

    oauthData: {
        kind: IdentityProviders;
        authToken?: string; //Google Token
        token?: string; //Facebook Token
        email?: string; //Email by Facebook
        accessToken?: string; //Apple token
        fullName?: string; //Apple Full Name
    }

    utmCodes?: {
        utmSource?: string;
        utmMedium?: string;
        utmCampaign?: string;
        utmTerm?: string;
        utmContent?: string;
    }
}

export interface OauthSessionData {
    person: BasePerson;
    /**
     * Flags the server did a signup
     */
    signup?: boolean;
}
export interface OauthSessionCreateResult extends ApiResource<OauthSessionData> {}

export class OauthSessionAutologinFailed extends FonomaError<ApiReturn> {}

export class OauthSessionMissingFacebookEmail extends FonomaError<ApiReturn> {
    fbToken: string;

    constructor(message: string, public wrapped: ApiReturn, fbToken: string) {
        super(message);
        this.fbToken = fbToken;
    }
}

export class OauthSessionAccountAlreadyExists extends FonomaError<ApiReturn> {}

export class OauthSessionBlacklistedDomain extends FonomaError<ApiReturn> {}

export class OauthSessionResource extends CreateResource<
    OauthSessionCreate,
    OauthSessionCreateResult
> {
    static readonly FONOMA_AUTOLOGIN_FAILED = -300;

    static readonly FONOMA_MISSING_FACEBOOK_EMAIL = -400;
    static readonly FONOMA_ACCOUNT_ALREADY_EXISTS = -100;
    static readonly FONOMA_BLACKLISTED_DOMAIN = -2;

    // TODO: Find a way to avoid this override... maybe we can get rid of the generic methods
    async create<
        TIn = OauthSessionCreate,
        TOut extends ApiResource = OauthSessionCreateResult
    >(model: TIn, options?: AxiosRequestConfig): Promise<TOut>;

    async create(
        model: OauthSessionCreate,
        options?: AxiosRequestConfig
    ): Promise<OauthSessionCreateResult> {
        let apiReturnOnFail;
        try {
            const res = await this.client.post<OauthSessionCreateResult>(
                `${this.name}`,
                model,
                options
            );
            if (res.data.errors?.length && res.data.errors[0].code !== 0) {
                apiReturnOnFail = res.data.errors[0];
            } else {
                return res.data;
            }
        } catch (e) {
            if (e.response?.data.errors?.length) {
                apiReturnOnFail = e.response.data.errors[0];
            } else {
                throw e;
            }
        }
        
        throw OauthSessionResource.translateToError(apiReturnOnFail, model);
    }

    static translateToError(
        apiReturn: ApiReturn,
        model: OauthSessionCreate
    ):
        | OauthSessionAutologinFailed
        | OauthSessionMissingFacebookEmail
        | FonomaError<ApiReturn> {
        switch (apiReturn.code) {
            case OauthSessionResource.FONOMA_MISSING_FACEBOOK_EMAIL:
                return new OauthSessionMissingFacebookEmail(
                    apiReturn.message,
                    apiReturn,
                    model.oauthData.token
                );
            case OauthSessionResource.FONOMA_AUTOLOGIN_FAILED:
                return new OauthSessionAutologinFailed(
                    apiReturn.message,
                    apiReturn
                );
            case OauthSessionResource.FONOMA_ACCOUNT_ALREADY_EXISTS:
                return new OauthSessionAccountAlreadyExists(
                    apiReturn.message,
                    apiReturn
                );
            case OauthSessionResource.FONOMA_BLACKLISTED_DOMAIN:
                return new OauthSessionBlacklistedDomain(
                    apiReturn.message,
                    apiReturn
                );
            default:
                return new FonomaError<ApiReturn>(apiReturn.message, apiReturn);
        }
    }
}

export const oauthSessionResource = new OauthSessionResource('oauth_sessions', axiosInstanceV2);
