import camelCase from 'lodash/camelCase';
import Cookies from 'universal-cookie';
import type {CookieSetOptions} from 'universal-cookie';
import {
    AuthResult,
    IdentityProviders,
} from '../../lib/identity-providers/identity-provider';
import {get, UtmMap} from '../../lib/utm';
import {getUserPreferences} from '../util/cookie-preferences/get-user-preferences';
import {getUtmCookieOptions} from '../util/cookie-preferences/get-utm-cookie-options';
import {
    OauthSessionCreate,
    OauthSessionCreateResult,
    oauthSessionResource,
} from './oauth-session-resource';
import {postSigninActions, PostSigninOptions} from './post-signin-actions';
import {AppleExtraData} from '../../lib/identity-providers/apple/apple-provider';
import {FacebookExtraData} from './facebook-sign-in/facebook-sign-in';
import {defaultPreferences} from '../util/cookie-preferences/preferences';

const KIND_TO_METHOD_MAP: Record<
    IdentityProviders,
    'facebook' | 'google' | 'apple'
> = {
    [IdentityProviders.Google]: 'google',
    [IdentityProviders.Facebook]: 'facebook',
    [IdentityProviders.Apple]: 'apple',
};

function setUtms(
    sessionData: OauthSessionCreate,
    utms: UtmMap
): OauthSessionCreate {
    const acc = {};
    for (const key in utms) {
        acc[camelCase(key)] = utms[key];
    }
    const accObj = {utmCodes: acc};
    return {...accObj, ...sessionData};
}

export interface PersonSigninOptions {
    remember?: boolean;
    utmCookieOptions?: CookieSetOptions;
    cookies?: Cookies;
    queryString?: string;
}
/**
 * Handles signing in with 3rd party providers
 */
export async function personSignIn<
    TExtra = {} | AppleExtraData | FacebookExtraData
>(
    authResult: AuthResult<TExtra>,
    {
        remember = false,
        utmCookieOptions = getUtmCookieOptions(),
        cookies: cookie = new Cookies(document.cookie),
        queryString = document.location.search,
    }: PersonSigninOptions = {}
): Promise<OauthSessionCreateResult> {
    const prefs = getUserPreferences(cookie, defaultPreferences);

    let oauthSessionData: OauthSessionCreate = {
        oauthData: {
            kind: authResult.kind,
        },
        autoLogin: authResult.isAutoLogin,
        currency: prefs.currency,
        lang: prefs.lang,
    };
    

    // Currently we have no way to determine this
    // In the legacy code: angular.extend(sessionData, {discount_info: self._discountOnLogin, promo_id: self._promoId});
    // promo_id is not used by the api resource but it is sent in the request
    // TODO: Review if we need to include that in the OauthSessionCreate interface

    // oauthSessionData.discountInfo = false;

    // set specific provider's data here
    switch (authResult.kind) {
        case IdentityProviders.Google:
            oauthSessionData.oauthData.authToken = authResult.token;
            break;
        case IdentityProviders.Facebook:
            oauthSessionData.oauthData.token = authResult.token;
            if (authResult.extra) {
                const extraData =
                    authResult.extra as unknown as FacebookExtraData;
                oauthSessionData.oauthData.email = extraData.email;
            }
            break;
        case IdentityProviders.Apple:
            oauthSessionData.oauthData.accessToken = authResult.token;
            if (authResult.extra) {
                const extraData = authResult.extra as unknown as AppleExtraData;
                oauthSessionData.oauthData.fullName = extraData.apFullName;
            }
            if (oauthSessionData.oauthData.fullName) {
                // If we have the full name, autoLogin should be false
                oauthSessionData.autoLogin = false;
            }
    }

    const utms = get(cookie, queryString, utmCookieOptions);
    oauthSessionData = setUtms(oauthSessionData, utms);
    const oauthResponse = await oauthSessionResource.create(oauthSessionData);
    const {signup, person} = oauthResponse.data;
    const postSigninOptions: PostSigninOptions = {
        autoLogin: authResult.isAutoLogin,
        remember,
    };
    if (signup) {
        postSigninOptions.signupMethod = KIND_TO_METHOD_MAP[authResult.kind];
    }
    await postSigninActions(person, utms, postSigninOptions);
    
    return oauthResponse;
}
