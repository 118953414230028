// eslint-disable-next-line import/named
import {DefaultTheme} from 'styled-components';

const ROOT_FONT_SIZE = 16;

const pxToRem = (size: number): string => `${size / ROOT_FONT_SIZE}rem`;

/**
 * Fonoma's base theme. Stores common constants used in our styled components
 *
 * As a general rule: Numerical values (like font sizes should be stored as numbers).
 */
export const FonomaBaseTheme: DefaultTheme = {
    fontFamily: '"Inter", sans-serif',
    fontSizes: {
        tinny: 12,
        small: 14,
        medium: 16,
        large: 24,
        xLarge: 32,
    },
    fontMeasureUnit: 'px',
    globalLetterSpacing: 0.5,
    textColor: '#444',
    unitsOfMeasure: {
        px: 'px',
    },
    desktopMeasures: {
        centeredContentWidth: 1440,
        navBarHeight: 76,
    },
    mobileMeasures: {
        navBarHeight: 64,
    },
    navBar: {
        backgroundColor: 'none',
    },
    bodyBackgroundColor: '#FFFFFF',
    colors: {
        arroz: '#FFFFFF',
        link: '#428bca',

        ballena: '#002C48',
        tiburon: '#648090',
        delfin: '#98ABB5',
        atun: '#D9E0E4',
        caballa: '#F2F4F6',
        plancton: '#00A8B5',
        info: '#72CEEE',
        error: '#E35131',
        planctonLink: '#0874ef',
    },
    loadingIndicator: {
        animationPath: '/static/landings/animations/spinner.json',
    },
    spacing: {
        xxs: '4px',
        xs: '8px',
        s: '12px',
        m: '16px',
        l: '24px',
        xl: '32px',
        xxl: '40px',
        xxxl: '44px', //as spacing-xxl-2 in zeplin ¯\_(ツ)_/¯
        xxxxl: '48px',
    },
    typography: {
        Display1: {
            fontSize: pxToRem(64),
            fontWeight: 'bold',
            lineHeight: 1.06,
        },
        Display2: {
            fontSize: pxToRem(56),
            fontWeight: 'bold',
            lineHeight: 1.2,
        },
        Display3: {
            fontSize: pxToRem(48),
            fontWeight: 'bold',
            lineHeight: 1.13,
        },
        Heading1: {
            fontSize: pxToRem(40),
            fontWeight: '600',
            lineHeight: 1.2,
        },
        Heading2: {
            fontSize: pxToRem(32),
            fontWeight: '600',
            lineHeight: 1.25,
        },
        Heading3: {
            fontSize: pxToRem(24),
            fontWeight: '600',
            lineHeight: 1.33,
        },
        Heading4: {
            fontSize: pxToRem(20),
            fontWeight: '600',
            lineHeight: 1.2,
        },
        LargeBody: {
            fontSize: pxToRem(20),
            fontWeight: 'normal',
            lineHeight: 1.4,
        },
        TitleBody: {
            fontSize: pxToRem(16),
            fontWeight: 600,
            lineHeight: 1.5,
        },
        Body: {
            fontSize: pxToRem(16),
            fontWeight: 'normal',
            lineHeight: 1.5,
        },
        LinkBody: {
            fontSize: pxToRem(16),
            fontWeight: 'normal',
            lineHeight: 1.5,
        },
        Label: {
            fontSize: pxToRem(16),
            fontWeight: 300,
            lineHeight: 1.5,
        },
        MediumBody: {
            fontSize: pxToRem(14),
            fontWeight: 300,
            lineHeight: 1.43,
        },
        SmallBody: {
            fontSize: pxToRem(12),
            fontWeight: 'normal',
            lineHeight: 1.33,
        },
    },
    gradients: {
        about: {background: 'radial-gradient(43.47% 85.52% at 100% 0%, rgba(0, 168, 181, 0.5) 0%, rgba(0, 168, 181, 0.5) 20%, rgba(0, 168, 181, 0) 100%) no-repeat 0 0 / 100% 250px, radial-gradient(61.62% 85.26% at 0% 0%, rgba(163, 90, 255, 0.4) 0%, rgba(163, 90, 255, 0.4) 20%, rgba(163, 90, 0, 0) 80.3%) no-repeat 0 0 / 100% 250px'},
        notFound: {background: 'radial-gradient(31.47% 31.52% at 85.07% -3.56%, rgba(0, 168, 181, 0.3) 0%, rgba(0, 168, 181, 0) 100%), radial-gradient(40.62% 40.26% at 19.79% -9.04%, rgba(90, 166, 255, 0.3) 0%, rgba(67, 153, 255, 0.3) 0.01%, rgba(163, 90, 255, 0) 80.3%)'},
        dialog: {background: 'radial-gradient(90% 40% at 135% 115%, rgba(90, 166, 255, 0.95) 0%, rgba(163, 90, 255, 0.25) 80%,rgba(255, 255, 255, 0) 100%), radial-gradient(100% 40% at -10% 115%, rgba(0, 168, 181, 0.85) 0%, rgba(255, 255, 255, 0) 100%)'},
    },
    grain: {
        dialog: {
        background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='5' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E") 0 0/250px auto`,
        pointerEvents: 'none',
        filter: 'contrast(200%) brightness(1000%)',
        opacity: 0.75,
        },
        page: {
            background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='5' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
            pointerEvents: 'none',
            filter: 'contrast(200%) brightness(1000%)',
            opacity: 0.75,
        }
    },
    logo: {
        desktop:{
            width: 144,
            height: 24,
        },
        mobile: {
            width: 118,
            height: 20,
        }
    },
};
