import axios, {
    AxiosRequestConfig,
    AxiosInstance,
    AxiosResponse,
    AxiosRequestTransformer,
    AxiosResponseTransformer,
} from 'axios';
import memoize from 'lodash/memoize';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import {createAxiosInstance} from './client';
import {API_V2_URL} from '../../modules/util/constants';

const memoSnakeCase = memoize(snakeCase);
const memoCamelCase = memoize(camelCase);

// we need feature detection for proxy since there is no fully compliant polyfill
// consider this function as private
export function hasProxySupport(): boolean {
    let hasProxy = true;
    try {
        new Proxy({}, {});
    } catch {
        hasProxy = false;
    }
    return hasProxy;
}

/**
 * Special symbol to store the raw object we receive from the server
 */
export const WRAPPED_RAW_OBJECT = Symbol.for('WRAPPED_RAW_OBJECT');

/**
 * Special symbol to mark a response data object as proxied
 */
export const IS_PROXY = Symbol.for('IS_PROXY');

/**
 * Base interface for proxied objects
 */
interface ProxiedModel {
    [IS_PROXY]?: boolean;
    [WRAPPED_RAW_OBJECT]?: Record<string, unknown>;
}
/**
 * Recursively clone a plain object or an array of plain objects
 * @param source
 */
export function deepClone(
    source: object,
    keyTransformerFn: (key: string) => string
): object {
    // we still need to differentiate from arrays
    if (isPlainObject(source)) {
        const clone = {};
        const keys = Object.keys(source);
        for (const key of keys) {
            clone[keyTransformerFn(key)] = deepClone(
                
                source[key],
                keyTransformerFn
            );
        }
        return clone;
    }

    if (isArray(source)) {
        return (source as []).map(function deepCloneMap(value) {
            return deepClone(value, keyTransformerFn);
        });
    }
    return source;
}

/**
 * Installs a Proxy in the source array to handle lazy "camelization" of nested objects
 *
 * @param source
 */
export function createResponseArrayProxy<T>(source: T[]): T[] {
    // we need to clone arrays in order to not mutate the src obj
    const target = [...source];
    const handler: ProxyHandler<T[]> = {
        get(target, index) {
            if (index in target) {
                // if value is an object, it will be a reference to that object
                
                const value = target[index];

                // install a proxy in {} elements
                if (isPlainObject(value)) {
                    return (target[index] = createResponseDataProxy(value));
                }

                // all arrays must be proxied. Handles [][] types
                if (isArray(value)) {
                    return (target[index] = createResponseArrayProxy(value));
                }
            }
            // pass any other access op to the target
            return target[index];
        },
    };
    return new Proxy(target, handler);
}

/**
 * Installs a Proxy in the source object to handle lazy "camelization"
 * @param source Raw object with snake case keys
 */
export function createResponseDataProxy<T extends object>(source: object): T {
    // don't proxy a proxy
    // don't proxy things other than plain objects
    if (source === null || source === undefined) return source as T;
    if (source[IS_PROXY] || !isPlainObject(source)) {
        return source as T;
    }

    // Create an empty object to be the target and save the source
    const proxied: T = {
        [IS_PROXY]: true,
        [WRAPPED_RAW_OBJECT]: source,
    } as unknown as T;

    const handler: ProxyHandler<T> = {
        get(target, key) {
            // if key already in target, return that value
            if (key in target) return target[key];

            // we only 'camelize' string keys (obviously)
            if (typeof key === 'string') {
                let value = target[WRAPPED_RAW_OBJECT][memoSnakeCase(key)];
                // handle nested objs at this level
                // we use isPlainObject because this handles objects from JSON.parse
                if (isPlainObject(value)) {
                    
                    value = createResponseDataProxy(value);
                }

                // we need to handle arrays with a different proxy
                if (isArray(value)) {
                    value = createResponseArrayProxy(value);
                }
                // we do no set a prop to undefined
                // at this point undefined means the prop does not exist
                if (value !== undefined) {
                    target[key] = value;
                }
                return value;
            }
            // pass any other access op to the target
            return target[key];
        },
        // this is for compatibility
        ownKeys(target) {
            
            const keys = Reflect.ownKeys(target[WRAPPED_RAW_OBJECT]);
            return keys.map(function mapStringKeysToCamelCase(key) {
                return typeof key === 'string' ? memoCamelCase(key) : key;
            });
        },
        getOwnPropertyDescriptor(target, prop) {
            let desc = Reflect.getOwnPropertyDescriptor(target, prop);
            if (!desc && typeof prop === 'string') {
                desc = Reflect.getOwnPropertyDescriptor(
                    
                    target[WRAPPED_RAW_OBJECT],
                    memoSnakeCase(prop)
                );
            }
            return desc;
        },
    };
    return new Proxy(proxied, handler);
}

export function createRequestArrayProxy<T>(source: T[]): T[] {
    const targetArr = [...source];
    const handler: ProxyHandler<T[]> = {
        get(target, index) {
            if (index in target) {
                let value = target[index];

                if (isPlainObject(value)) {
                    value = createResquestDataProxy(value);
                }
                if (isArray(value)) {
                    value = createRequestArrayProxy(value);
                }
                return value;
            }
            return target[index];
        },
    };
    return new Proxy(targetArr, handler);
}

export function createResquestDataProxy<T extends object>(source: T): T {
    if (source === null || source === undefined) return source;
    if (!isPlainObject(source) || source[IS_PROXY]) return source;

    const target: T = {} as T;
    // its useful to know if a object is a proxy
    Object.defineProperty(target, IS_PROXY, {
        enumerable: false,
        value: true,
    });

    const handler: ProxyHandler<T> = {
        get(target, prop) {
            if (typeof prop === 'string') {
                // for request data, we just create a closure over source
                let value = Reflect.get(source, memoCamelCase(prop));

                if (isPlainObject(value)) {
                    // @ts-ignore
                    value = createResquestDataProxy(value);
                }

                if (isArray(value)) {
                    // @ts-ignore
                    value = createRequestArrayProxy(value);
                }
                return value;
            }

            return Reflect.get(target, prop);
        },
        ownKeys() {
            // we need to trick `JSON.stringify`
            // TODO: given we are mapping all the keys,
            // should we recursively map the whole object and discard this proxy?
            const keys = Reflect.ownKeys(source);
            keys.push(IS_PROXY);
            return keys.map(function mapKeys(key) {
                if (typeof key === 'string') return memoSnakeCase(key);
                return key;
            });
        },
        getOwnPropertyDescriptor(target, p) {
            // for some reason, Object.toJSON needs the property descriptor
            // probably to check if the prop is enumerable
            // p could be a symbol
            if (typeof p === 'string') {
                return Reflect.getOwnPropertyDescriptor(
                    source,
                    memoCamelCase(p)
                );
            }
            return Reflect.getOwnPropertyDescriptor(target, p);
        },
    };
    return new Proxy(target, handler);
}

// contain error codes and sometimes a descriptive message
export type ApiReturn<T = Record<string, unknown>> = {
    //TODO: msg & data fields must be removed when all api v2 endpoints migration finish
    //Also message is mandatory
    code: number;
    msg?: string;
    message?: string;
    details?: any;
    data?: T;
    traceback?: string;
    className?: string;
};

/**
 * Base interface for all resource responses
 */
export interface ApiResource<TData = any, TErrorData = Record<string, unknown>>
    extends ProxiedModel {
    apiReturn?: ApiReturn<TErrorData>; //Delete when all endpoints are migrated to v2
    errors?: ApiReturn<TErrorData>[];
    data?: TData;
}

/**
 * Interface for list operation
 */
export interface ApiResourceList<T extends ApiResource> extends ApiResource {
    meta: {
        limit: number;
        next: string;
        nextCursor: string;
        previous: string;
        previousCursor: string;
        totalCount: number;
    };
    objects: Omit<T, 'apiReturn'>[];
}

export function createResponseTransformer(
    hasProxy = true
): AxiosResponseTransformer {
    return function transformResponse(data: object): object {
        return hasProxy
            ? createResponseDataProxy(data)
            : deepClone(data, memoCamelCase);
    };
}

export function createRequestTransformer(
    hasProxy = true
): AxiosRequestTransformer {
    return function transformRequest(data: object): object {
        return hasProxy
            ? createResquestDataProxy(data)
            : deepClone(data, memoSnakeCase);
    };
}

/**
 * Default client shared by all resources
 */
export const defaultAxiosInstance = createAxiosInstance();
export const axiosInstanceV2 = createAxiosInstance(API_V2_URL);

const defaultRequestTransformer = createRequestTransformer(hasProxySupport());

const defaultResponseTranformer = createResponseTransformer(hasProxySupport());

/**
 * Value used to indicate there is no error in the response
 */
export const NoErrorResponse = Symbol.for('NoErrorResponse');
export type NoErrorResponseType = typeof NoErrorResponse;

/**
 * Generic Resource class to handle communication with th API.
 *
 * Since a resource can have a different shape in their data for each operation (create, get, update ...)
 * this class allow to define different types for those operations.
 */
export class Resource<
    TGetOutput extends ApiResource,
    TList = ApiResourceList<TGetOutput>,
    TCreateInput = TGetOutput,
    TCreateOutput = TCreateInput,
    TUpdateInput = TGetOutput,
    TUpdateOutput = TUpdateInput,
    TDelete extends ApiResource = ApiResource
> {
    constructor(
        protected name: string,
        public client: AxiosInstance = defaultAxiosInstance,
        requestTransformer: AxiosRequestTransformer = defaultRequestTransformer,
        responseTransformer: AxiosResponseTransformer = defaultResponseTranformer
    ) {
        (
            this.client.defaults.transformRequest as AxiosRequestTransformer[]
        ).unshift(requestTransformer);
        (
            this.client.defaults.transformResponse as AxiosResponseTransformer[]
        ).push(responseTransformer);
    }

    /**
     * Makes an arbitrary request to the resource
     * All the configuration must be provided
     */
    async request<TIn = TGetOutput, TOut extends ApiResource = TIn>(
        config?: Omit<AxiosRequestConfig, 'url'>
    ): Promise<TOut> {
        const axiosConfig: AxiosRequestConfig = {...config, url: this.name};
        const response = await this.client.request<TIn, AxiosResponse<TOut>>(
            axiosConfig
        );
        return response.data;
    }
    /**
     * Override this method to customize error mapping
     * @param response {AxiosResponse<ApiResource>} The response received from the server
     * @returns {Error | NoErrorResponseType}
     */
    protected translateToError(
        response: AxiosResponse<ApiResource>
    ): Error | NoErrorResponseType {

        if (response.data.apiReturn) {
            // Any unhandled error
            return new Error(
                `Api error with Code: ${response.data.apiReturn.code},
                Message: ${response.data.apiReturn.msg},
                HTTP Status: ${response.status}`
            );
        }
    
        if (response.data.errors && response.data.errors.length > 0) {
            const errorDetail = response.data.errors[0];
            const code = errorDetail.code;
            const msg = errorDetail.message;
    
            // Any unhandled error
            return new Error(
                `Api error with Code: ${code},
                Message: ${msg},
                HTTP Status: ${response.status}`
            );
        }

        //Unknown error
        return new Error(
            `Unknown error,
            Message: Unknown error,
            HTTP Status: Unknown`
        );
    }

    /**
     * Executes the request (awaits the promise) and invokes the error translation function with the response
     *
     * For valid http codes (2**), it checks if a special value is returned, indicating there is no error.
     *
     * @param request The request to be executed
     * @returns
     */
    private async requestExecutor<T extends ApiResource>(
        request: Promise<AxiosResponse<T>>
    ): Promise<AxiosResponse<T>> {
        try {
            const response = await request;

            if (response.data.apiReturn) {
                if (response.data.apiReturn.code !== 0) {
                    const maybeError = this.translateToError(response);
                    if (maybeError === NoErrorResponse) return response;
                    throw maybeError;
                }
                return response;
            }

            if (response.data.errors && response.data.errors.length > 0) {
                const maybeError = this.translateToError(response);
                if (maybeError === NoErrorResponse) return response;
                throw maybeError;
            }

            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {

                throw this.translateToError(e.response);
            }
            throw e;
        }
    }

    async get<T extends ApiResource = TGetOutput>(
        id: string | number,
        options?: AxiosRequestConfig
    ): Promise<T>;
    async get(
        id: string | number,
        options?: AxiosRequestConfig
    ): Promise<TGetOutput> {
        const response = await this.requestExecutor(
            this.client.get<TGetOutput>(`${this.name}/${id}`, options)
        );
        return response.data;
    }

    async list<T extends ApiResource = TList>(
        options?: AxiosRequestConfig
    ): Promise<T>;
    async list(options?: AxiosRequestConfig): Promise<TList> {
        const response = await this.requestExecutor(
            this.client.get<TList>(`${this.name}`, options)
        );
        return response.data;
    }

    async create<TIn = TCreateInput, TOut extends ApiResource = TCreateOutput>(
        model: TIn,
        options?: AxiosRequestConfig
    ): Promise<TOut>;
    async create(
        model: TCreateInput,
        options?: AxiosRequestConfig
    ): Promise<TCreateOutput> {
        const response = await this.requestExecutor(
            this.client.post<TCreateInput, AxiosResponse<TCreateOutput>>(
                `${this.name}`,
                model,
                options
            )
        );
        return response.data;
    }

    async update<TIn = TUpdateInput, TOut extends ApiResource = TUpdateOutput>(
        id: string | number,
        model: TIn,
        options?: AxiosRequestConfig
    ): Promise<TOut>;
    async update(
        id: string | number,
        model: TUpdateInput,
        options?: AxiosRequestConfig
    ): Promise<TUpdateOutput> {
        const response = await this.requestExecutor(
            this.client.put<TUpdateInput, AxiosResponse<TUpdateOutput>>(
                `${this.name}/${id}`,
                model,
                options
            )
        );
        return response.data;
    }

    async delete<T extends ApiResource = TDelete>(
        id: string | number,
        options?: AxiosRequestConfig
    ): Promise<T>;
    async delete(
        id: string | number,
        options?: AxiosRequestConfig
    ): Promise<TDelete> {
        const response = await this.client.delete<TDelete>(
            `${this.name}/${id}`,
            options
        );
        return response.data;
    }
}

export class CreateResource<
    TCreateIn,
    TCreateOut extends ApiResource
> extends Resource<never, never, TCreateIn, TCreateOut, never, never, never> {}
