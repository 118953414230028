// eslint-disable-next-line import/named
import {DefaultTheme} from 'styled-components';

const ROOT_FONT_SIZE = 16;

const pxToRem = (size: number): string => `${size / ROOT_FONT_SIZE}rem`;

/**
 * Fonoma's base theme. Stores common constants used in our styled components
 *
 * As a general rule: Numerical values (like font sizes should be stored as numbers).
 */
export const RetopeBaseTheme: DefaultTheme = {
        fontFamily: '"Inter", sans-serif',
        fontSizes: {
            tinny: 12,
            small: 14,
            medium: 16,
            large: 24,
            xLarge: 32,
        },
        fontMeasureUnit: 'px',
        globalLetterSpacing: 0.5,
        textColor: '#444',
        unitsOfMeasure: {
            px: 'px',
        },
        desktopMeasures: {
            centeredContentWidth: 1440,
            navBarHeight: 76,
        },
        mobileMeasures: {
            navBarHeight: 64,
        },
        navBar: {
            backgroundColor: 'none',
        },
        bodyBackgroundColor: '#FFFFFF',
        colors: {
            arroz: '#FFFFFF',
            link: '#428bca',
            ballena: '#002c48',
            tiburon: '#648090',
            delfin: '#98abb5',
            atun: '#d9e0e4',
            caballa: '#f2f4f6',
            plancton: '#0660c6',
            info: '#95d9f6',
            error: '#c71606',
            planctonLink: '#0874ef'
        },
        loadingIndicator: {
            animationPath: '/static/landings/animations/spinner-teloa.json',
        },
        spacing: {
            xxs: '4px',
            xs: '8px',
            s: '12px',
            m: '16px',
            l: '24px',
            xl: '32px',
            xxl: '40px',
            xxxl: '44px', //as spacing-xxl-2 in zeplin ¯\_(ツ)_/¯
            xxxxl: '48px',
        },
        typography: {
            Display1: {
                fontSize: pxToRem(64),
                fontWeight: 'bold',
                lineHeight: 1.06,
            },
            Display2: {
                fontSize: pxToRem(56),
                fontWeight: 'bold',
                lineHeight: 1.2,
            },
            Display3: {
                fontSize: pxToRem(48),
                fontWeight: 'bold',
                lineHeight: 1.13,
            },
            Heading1: {
                fontSize: pxToRem(40),
                fontWeight: '600',
                lineHeight: 1.2,
            },
            Heading2: {
                fontSize: pxToRem(32),
                fontWeight: '600',
                lineHeight: 1.25,
            },
            Heading3: {
                fontSize: pxToRem(24),
                fontWeight: '600',
                lineHeight: 1.33,
            },
            Heading4: {
                fontSize: pxToRem(20),
                fontWeight: '600',
                lineHeight: 1.2,
            },
            LargeBody: {
                fontSize: pxToRem(20),
                fontWeight: 'normal',
                lineHeight: 1.4,
            },
            TitleBody: {
                fontSize: pxToRem(16),
                fontWeight: 600,
                lineHeight: 1.5,
            },
            Body: {
                fontSize: pxToRem(16),
                fontWeight: 'normal',
                lineHeight: 1.5,
            },
            LinkBody: {
                fontSize: pxToRem(16),
                fontWeight: 'normal',
                lineHeight: 1.5,
            },
            Label: {
                fontSize: pxToRem(16),
                fontWeight: 300,
                lineHeight: 1.5,
            },
            MediumBody: {
                fontSize: pxToRem(14),
                fontWeight: 300,
                lineHeight: 1.43,
            },
            SmallBody: {
                fontSize: pxToRem(12),
                fontWeight: 'normal',
                lineHeight: 1.33,
            },
        },
        gradients: {
            about: {
                background: `url('../../static/backgrounds/page-back.svg') no-repeat top/cover`,
            },
            dialog: {
                background: `url('../../static/backgrounds/modal-back.svg') no-repeat bottom`,
            },
            notFound: {
                background: `url('../../static/backgrounds/page-back.svg') no-repeat top/cover`,
            }
        },
        grain: {
            dialog: {background: 'inherit'},
            page: {background: 'inherit'},
        },
        logo: {
            desktop:{
                width: 107,
                height: 24,
            },
            mobile: {
                width: 89,
                height: 20,
            }
        },
    }
;
