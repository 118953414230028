import {parseQuery, UtmMap} from '../../lib/utm';
import {Person} from '../person/person-resource';
import {UAParser} from 'ua-parser-js';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'universal-cookie';
import {
    FACEBOOK_FBC_COOKIE_NAME,
    FACEBOOK_FBP_COOKIE_NAME,
    TIKTOK_QUERY_NAME,
} from '../util/constants';

export interface Traits {
    created: Date;
    email: string;
    firstName: string;
    name: string;
    loginType: string;
    lastName: string;
    facebookID: string;
}

// Maps UTM property names with
const utmPropertyNameMap = {
    utm_campaign: 'name',
    utm_content: 'content',
    utm_medium: 'medium',
    utm_source: 'source',
    utm_banner: 'banner',
    utm_adset: 'adset',
};

/**
 * Given an object utms, it returns a new object
 * whose keys are the same as in the original but
 * with a prefix.
 *
 * Example
 *
 * const utms = {utm_campaign: 'extra_40_05'}
 *
 * const newUtms = utm_campaign(utms, 'new_prefix_');
 *
 * // newUtms
 * {new_prefix_utm_campaign: 'extra_40_05'}
 *
 * @param utms Utms
 * @param prefix Prefix to add
 */
export function prefixInitialUtms(utms: UtmMap, prefix = 'initial_'): UtmMap {
    const ret = {};
    for (const key in utms) {
        ret[`${prefix}${key}`] = utms[key];
    }
    return ret;
}

/**
 * Given a person, returns its traits.
 * @param person
 */
export function getTraits(person: Person): Traits {
    return {
        created: new Date(person.dateJoined * 1000),
        email: person.email,
        firstName: person.firstName,
        name: person.fullName,
        loginType: person.type,
        lastName: person.fullName?.split(' ')[1],
        facebookID: person.fbId || null,
    } as Traits;
}

/**
 * Returns the campaign data on a utm
 * @param utms
 */
export function getCampaignData(utms: UtmMap): {} {
    const data = {};
    for (const key in utms) {
        if (utmPropertyNameMap[key] !== undefined) {
            data[utmPropertyNameMap[key]] = utms[key];
        }
    }
    return data;
}

export function getCommonAnalyticsData(
    person: Person,
    utms: UtmMap,
    properties: {},
    options = {}
): [{}, {}] {
    const uaParser = new UAParser(),
        osInfo = uaParser.getOS(),
        device = uaParser.getDevice();

    const cookies = new Cookies(document.cookie);

    const opts = {
        ...options,
        ...{
            // https://github.com/fonoma/web-app/blob/043ee50983599c117edef8076019702bad41df24/static/js/modules/lib/analytics.service.js#L98
            context: {
                os: osInfo,
                traits: {
                    ...properties,
                    facebookFbc:
                        cookies.get<string>(FACEBOOK_FBC_COOKIE_NAME) || null,
                    facebookFbp:
                        cookies.get<string>(FACEBOOK_FBP_COOKIE_NAME) || null,
                    ttclid:
                        parseQuery(location.search)[TIKTOK_QUERY_NAME] || null,
                },
            },
        },
    };
    const props = {
        ...properties,
        ...{
            $created: new Date(person.dateJoined * 1000),
            currency: person.currency,
            lang: person.lang,
        },
    };

    Object.assign(opts.context, {$device: device});
    if (
        osInfo.name &&
        ['Android', 'BlackBerry', 'Windows Phone'].includes(osInfo.name)
    ) {
        Object.assign(props, {$device: osInfo.name});
    } else if (device.model && ['iPhone', 'iPad'].includes(device.model)) {
        Object.assign(props, {$device: device.model});
    }

    if (!isEmpty(utms)) {
        // https://github.com/fonoma/web-app/blob/043ee50983599c117edef8076019702bad41df24/static/js/modules/lib/analytics.service.js#L71
        Object.assign(props, utms);

        // https://github.com/fonoma/web-app/blob/043ee50983599c117edef8076019702bad41df24/static/js/modules/lib/analytics.service.js#L74
        Object.assign(opts, {campaign: {...getCampaignData(utms)}});
    }

    // Include the referring domain
    // https://github.com/fonoma/web-app/blob/043ee50983599c117edef8076019702bad41df24/static/js/modules/lib/analytics.service.js#L90
    if (document.referrer !== '')
        Object.assign(props, {
            $referring_domain: new URL(document.referrer).hostname,
        });

    //https://github.com/fonoma/web-app/blob/043ee50983599c117edef8076019702bad41df24/static/js/modules/lib/analytics.service.js#L93
    Object.assign(props, {fnmClient: 'web'});

    return [props, opts];
}
