import {Person} from './person-resource';
import {BaseState} from '../util/base-state';
export interface PersonState extends BaseState {
    person?: Person;
    isLoggedIn: boolean;
    signedUp: boolean;
}

export const defaultInitialState: PersonState = {
    loading: false,
    error: null,
    isLoggedIn: false,
    person: null,
    signedUp: false,
};

export const CHANGE_LANG_REQUEST = 'person/CHANGE_LANG_REQUEST';
export const CHANGE_LANG_SUCCESS = 'person/CHANGE_LANG_SUCCESS';
export const CHANGE_LANG_ERROR = 'person/CHANGE_LANG_ERROR';
export const LOGIN_SUCCESS = 'person/LOGIN_SUCCESS';
export const SET_ME_CONTACT_REQUEST = 'person/SET_ME_CONTACT_REQUEST';

export interface ChangeLangRequest {
    type: typeof CHANGE_LANG_REQUEST;
}

export interface ChangeLangSuccess {
    type: typeof CHANGE_LANG_SUCCESS;
    lang: string;
}

export interface ChangeLangError {
    type: typeof CHANGE_LANG_ERROR;
    error?: Record<string, unknown>;
}

export interface LoginSuccess {
    type: typeof LOGIN_SUCCESS;
    person: Person;
    signup: boolean;
}

export interface SetMeContactSuccess {
    type: typeof SET_ME_CONTACT_REQUEST;
    person: Person;
    signup: boolean;
}

export type PersonActionTypes =
    | ChangeLangRequest
    | ChangeLangSuccess
    | ChangeLangError
    | LoginSuccess
    | SetMeContactSuccess;

export function personReducer(
    state: PersonState,
    action: PersonActionTypes
): PersonState {
    switch (action.type) {
        case CHANGE_LANG_REQUEST:
            return {...state, loading: true};
        case CHANGE_LANG_SUCCESS:
            return {
                ...state,
                loading: false,
                person: {...state.person, lang: action.lang},
            };
        case CHANGE_LANG_ERROR:
            return {...state, loading: false, error: action.error};
        case LOGIN_SUCCESS: {
            const {signup, person} = action;
            return {
                ...state,
                loading: false,
                person: person,
                isLoggedIn: true,
                signedUp: signup,
                error: null,
            };
        }
        case SET_ME_CONTACT_REQUEST: {
            const {signup, person} = action;
            return {
                ...state,
                loading: false,
                person: person,
                isLoggedIn: false,
                signedUp: signup,
                error: null,
            };
        }

        // this should never happen but if it does, i'll let you know
        default:
            throw new Error(
                `Invalid action received in personReducer. Action: ${JSON.stringify(
                    action
                )}`
            );
    }
}
